var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card shadow-none"},[_c('div',{staticClass:"card-header"},[_vm._v("Framingham")]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"form-group col-lg-4 col-md-12 col-sm-12"},[_c('label',{staticClass:"form-label font-weight-bolder"},[_vm._v("Género")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.genero),expression:"genero"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.genero=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"F"}},[_vm._v("F")]),_c('option',{attrs:{"value":"M"}},[_vm._v("M")])])]),_c('div',{staticClass:"form-group col-lg-4 col-md-12 col-sm-12"},[_c('label',{staticClass:"form-label font-weight-bolder"},[_vm._v("Edad")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.edad_u),expression:"edad_u"}],staticClass:"form-control",attrs:{"type":"number"},domProps:{"value":(_vm.edad_u)},on:{"input":function($event){if($event.target.composing){ return; }_vm.edad_u=$event.target.value}}})]),_c('div',{staticClass:"form-group col-lg-4 col-md-12 col-sm-12"},[_c('label',{staticClass:"form-label font-weight-bolder"},[_vm._v("TA. Sistólica")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ta_sistolica),expression:"ta_sistolica"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$v.ta_sistolica.$error && _vm.$v.ta_sistolica.$dirty,
          },attrs:{"type":"number"},domProps:{"value":(_vm.ta_sistolica)},on:{"input":function($event){if($event.target.composing){ return; }_vm.ta_sistolica=$event.target.value}}})]),_c('div',{staticClass:"form-group col-lg-4 col-md-12 col-sm-12"},[_c('label',{staticClass:"form-label font-weight-bolder"},[_vm._v("Col Total")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.col_total),expression:"col_total"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$v.col_total.$error && _vm.$v.col_total.$dirty,
          },attrs:{"type":"number"},domProps:{"value":(_vm.col_total)},on:{"input":function($event){if($event.target.composing){ return; }_vm.col_total=$event.target.value}}})]),_c('div',{staticClass:"form-group col-lg-4 col-md-12 col-sm-12"},[_c('label',{staticClass:"form-label font-weight-bolder"},[_vm._v("Col HDL")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.col_hdl),expression:"col_hdl"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$v.col_hdl.$error && _vm.$v.col_hdl.$dirty,
          },attrs:{"type":"number"},domProps:{"value":(_vm.col_hdl)},on:{"input":function($event){if($event.target.composing){ return; }_vm.col_hdl=$event.target.value}}})]),_c('div',{staticClass:"form-group col-lg-4 col-md-12 col-sm-12"},[_c('label',{staticClass:"form-label font-weight-bolder"},[_vm._v("Tratamiento Hipertensión")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.tratamiento_hipertension),expression:"tratamiento_hipertension"}],staticClass:"form-control",class:{
            'is-invalid':
              _vm.$v.tratamiento_hipertension.$error &&
              _vm.$v.tratamiento_hipertension.$dirty,
          },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.tratamiento_hipertension=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"y"}},[_vm._v("SI")]),_c('option',{attrs:{"value":"n"}},[_vm._v("NO")])])]),_c('div',{staticClass:"form-group col-lg-4 col-md-12 col-sm-12"},[_c('label',{staticClass:"form-label font-weight-bolder"},[_vm._v("Fuma Cigarrillos")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.fuma_cigarrillos),expression:"fuma_cigarrillos"}],staticClass:"form-control",class:{
            'is-invalid':
              _vm.$v.fuma_cigarrillos.$error && _vm.$v.fuma_cigarrillos.$dirty,
          },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.fuma_cigarrillos=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"y"}},[_vm._v("SI")]),_c('option',{attrs:{"value":"n"}},[_vm._v("NO")])])]),_c('div',{staticClass:"form-group col-lg-4 col-md-12 col-sm-12"},[_c('label',{staticClass:"form-label font-weight-bolder"},[_vm._v("Presencia Diabetes")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.presencia_diabetes),expression:"presencia_diabetes"}],staticClass:"form-control",class:{
            'is-invalid':
              _vm.$v.presencia_diabetes.$error && _vm.$v.presencia_diabetes.$dirty,
          },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.presencia_diabetes=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"y"}},[_vm._v("SI")]),_c('option',{attrs:{"value":"n"}},[_vm._v("NO")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 font-weight-bolder"},[_vm._v(" porcentaje : "+_vm._s(this.porcentaje)+" ")]),_c('div',{staticClass:"col-6 font-weight-bolder"},[_vm._v("Riesgo : "+_vm._s(this.riesgo))])]),_c('div',{staticClass:"row mt-2"},[_c('button',{staticClass:"btn btn-secondary mx-2 btn-sm",on:{"click":function($event){return _vm.calcular()}}},[_c('i',{staticClass:"fa fa-calculator fa-fw"}),_vm._v(" Calcular ")]),_c('button',{staticClass:"btn btn-success btn-sm",on:{"click":function($event){return _vm.guardar()}}},[_c('i',{staticClass:"fa fa-save fa-fw"}),_vm._v(" Guardar ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }