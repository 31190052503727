var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('div',{staticClass:"card shadow-none mt-2"},[_c('div',{staticClass:"card-header"},[_vm._v("Finnish Risk (FINDRISC)")]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"form-group col-lg-4 col-md-12 col-sm-12"},[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Género")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataRisk.genero),expression:"dataRisk.genero"}],staticClass:"form-control",class:{
              'is-invalid':
                _vm.$v.dataRisk.genero.$error && _vm.$v.dataRisk.genero.$dirty,
            },attrs:{"readonly":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.dataRisk, "genero", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"F"}},[_vm._v("F")]),_c('option',{attrs:{"value":"M"}},[_vm._v("M")])])]),_c('div',{staticClass:"form-group col-lg-4 col-md-12 col-sm-12"},[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Edad")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.edad_u),expression:"edad_u"}],staticClass:"form-control",attrs:{"type":"number","readonly":""},domProps:{"value":(_vm.edad_u)},on:{"input":function($event){if($event.target.composing){ return; }_vm.edad_u=$event.target.value}}})]),_c('div',{staticClass:"form-group col-lg-4 col-md-12 col-sm-12"},[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("IMC")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.indice_masa_corporal),expression:"indice_masa_corporal"}],staticClass:"form-control",class:{
              'is-invalid':
                _vm.$v.indice_masa_corporal.$error &&
                _vm.$v.indice_masa_corporal.$dirty,
            },attrs:{"type":"number","readonly":""},domProps:{"value":(_vm.indice_masa_corporal)},on:{"input":function($event){if($event.target.composing){ return; }_vm.indice_masa_corporal=$event.target.value}}})]),_c('div',{staticClass:"form-group col-lg-4 col-md-12 col-sm-12"},[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Usa medicamentos para la presión")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataRisk.medicamentos_presion),expression:"dataRisk.medicamentos_presion"}],staticClass:"form-control",class:{
              'is-invalid':
                _vm.$v.dataRisk.medicamentos_presion.$error &&
                _vm.$v.dataRisk.medicamentos_presion.$dirty,
            },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.dataRisk, "medicamentos_presion", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"y"}},[_vm._v("SI")]),_c('option',{attrs:{"value":"n"}},[_vm._v("NO")])])]),_c('div',{staticClass:"form-group col-lg-4 col-md-12 col-sm-12"},[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Antecedentes personales de diabetes")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataRisk.historial_glucosa),expression:"dataRisk.historial_glucosa"}],staticClass:"form-control",class:{
              'is-invalid':
                _vm.$v.dataRisk.historial_glucosa.$error &&
                _vm.$v.dataRisk.historial_glucosa.$dirty,
            },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.dataRisk, "historial_glucosa", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"y"}},[_vm._v("SI")]),_c('option',{attrs:{"value":"n"}},[_vm._v("NO")])])]),_c('div',{staticClass:"form-group col-lg-4 col-md-12 col-sm-12"},[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Actividad fisica en horas a la semana")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataRisk.actividad_fisica),expression:"dataRisk.actividad_fisica"}],staticClass:"form-control",class:{
              'is-invalid':
                _vm.$v.dataRisk.actividad_fisica.$error &&
                _vm.$v.dataRisk.actividad_fisica.$dirty,
            },attrs:{"type":"number"},domProps:{"value":(_vm.dataRisk.actividad_fisica)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dataRisk, "actividad_fisica", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-lg-4 col-md-12 col-sm-12"},[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Consumo diario de frutas")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataRisk.consumo_diario_frutas),expression:"dataRisk.consumo_diario_frutas"}],staticClass:"form-control",class:{
              'is-invalid':
                _vm.$v.dataRisk.consumo_diario_frutas.$error &&
                _vm.$v.dataRisk.consumo_diario_frutas.$dirty,
            },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.dataRisk, "consumo_diario_frutas", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"y"}},[_vm._v("SI")]),_c('option',{attrs:{"value":"n"}},[_vm._v("NO")])])]),_c('div',{staticClass:"form-group col-lg-4 col-md-12 col-sm-12"},[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Antecedentes familiares de diabetes")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataRisk.historial_diabetes),expression:"dataRisk.historial_diabetes"}],staticClass:"form-control",class:{
              'is-invalid':
                _vm.$v.dataRisk.historial_diabetes.$error &&
                _vm.$v.dataRisk.historial_diabetes.$dirty,
            },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.dataRisk, "historial_diabetes", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"n"}},[_vm._v("No")]),_c('option',{attrs:{"value":"1g"}},[_vm._v("1 Grado")]),_c('option',{attrs:{"value":"2g"}},[_vm._v("2 Grado")])])]),_c('div',{staticClass:"form-group col-lg-4 col-md-12 col-sm-12"},[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Perimetro abdominal")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataRisk.perimetro_abdominal),expression:"dataRisk.perimetro_abdominal"}],staticClass:"form-control",class:{
              'is-invalid':
                _vm.$v.dataRisk.perimetro_abdominal.$error &&
                _vm.$v.dataRisk.perimetro_abdominal.$dirty,
            },attrs:{"type":"number","step":"any"},domProps:{"value":(_vm.dataRisk.perimetro_abdominal)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dataRisk, "perimetro_abdominal", $event.target.value)}}})])])]),_c('div',{staticClass:"card-footer d-flex"},[_c('div',{staticClass:"list-group mt-2 col-lg-8 col-md-12 col-sm-12"},[_c('div',{staticClass:"list-group-item"},[_c('span',{staticClass:"font-weight-bolder badge badge-light badge-marketing mx-2"},[_vm._v("Puntaje : "+_vm._s(this.puntaje))]),_c('span',{staticClass:"font-weight-bolder",class:_vm.class_risk},[_vm._v("Riesgo: "+_vm._s(this.dataRisk.riesgo))])]),(_vm.$v.puntaje.$error && _vm.$v.puntaje.$dirty)?_c('div',{staticClass:"alert alert-danger mt-2"},[_vm._v(" Debe calcular el puntaje ")]):_vm._e()]),_c('div',{staticClass:"mt-3"},[_c('button',{staticClass:"btn btn-success mx-2 btn-sm",on:{"click":function($event){return _vm.calcular()}}},[_c('i',{staticClass:"fa fa-calculator fa-fw"}),_vm._v("Calcular Puntaje ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }